.content {
  text-align: center;

  .download-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    margin-bottom: 30px;
  }
}
@primary-color: #2664DD;@white-color: #FFFFFF;@black-color: #000000;@error-color: #FF4D4F;@warning-color: #FAAD14;@success-color: #52C41A;@link-color: #1890FF;@border-color: #D9D9D9;@secondary-text-color: rgba(0, 0, 0, 0.48);@body-text-color: rgba(0, 0, 0, 0.64);@title-color: rgba(0, 0, 0, 0.96);