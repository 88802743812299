.vui-stacked-card {
  height: 100%;

  .info-wrapper {
    margin-bottom: 15px;

    .title {
      font-size: 14px;
      margin-bottom: 5px;
    }

    .subtitle {
      color: rgb(131, 146, 165);
      margin-bottom: 0px;
    }
  }

  .list-wrapper {
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
    margin-top: 15px;

    .item {
      flex-basis: calc((100% / 2) - 15px) ;
      display: flex;
      flex-direction: column;

      .value {
        font-size: 24px;
        font-weight: 500;
        line-height: 1.2;
      }

      .label {
        font-size: 12px;
        font-weight: 600;
        margin-bottom: 5px;
      }


      .description {
        color: #8392a5;
        font-size: 12px;
        margin: 0px;
      }
    }
  }
}

@primary-color: #2664DD;@white-color: #FFFFFF;@black-color: #000000;@error-color: #FF4D4F;@warning-color: #FAAD14;@success-color: #52C41A;@link-color: #1890FF;@border-color: #D9D9D9;@secondary-text-color: rgba(0, 0, 0, 0.48);@body-text-color: rgba(0, 0, 0, 0.64);@title-color: rgba(0, 0, 0, 0.96);