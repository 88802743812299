.btn-google {
  border-color: @goggle;
  color: @goggle;

  &:hover {
    border-color: @goggle;
    background: @goggle;
    color: white;
  }
}

.btn-facebook {
  border-color: @facebook;
  color: @facebook;

  &:hover {
    border-color: @facebook;
    background: @facebook;
    color: white;
  }
}

.cursor-pointer {
  cursor: pointer;
}
