// .ant-form {
//   .ant-form-item-label {
//   }
// }

.ant-input-number-affix-wrapper {
  width: 100%;
}

.ant-radio-checked .ant-radio-inner::after {
  top: 50%;
  left: 50%;
}

.ant-select {
  &.ant-select-show-search {
    width: 100%;
  }
}

.ant-form-item-explain {
  font-size: 85%;
}

a {
  color: @primary-color;
}

.ant-btn {
  border-radius: 4px !important;
  span {
    font-size: 14px;
    font-weight: 500;

    &.anticon {
      font-size: unset;
      font-weight: 400;
    }
  }

  &.ant-btn-lg {
    span {
      font-size: 16px;
    }
  }
  &.ant-btn-background-ghost {
    background-color: transparent;
    &:hover {
      background-color: transparent;
    }
  }

  &.ant-btn-link {
    color: #2664dd !important;
  }
}

.ant-card-head {
  @media only screen and (max-width: @screen-lg) {
    padding: 0 15px;
  }
}

.ant-card-head-title {
  white-space: normal;
  font-size: 14px;
}

.ant-card-body {
  @media only screen and (max-width: @screen-lg) {
    padding: 24px 15px;
  }
}

.ant-card-head-wrapper {
  @media only screen and (max-width: @screen-lg) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.ant-card-extra {
  @media only screen and (max-width: @screen-lg) {
    margin-left: unset;
  }
}

.ant-table-content {
  @media only screen and (max-width: @screen-md) {
    overflow-y: scroll;
  }
}

@media only screen and (max-width: @screen-sm) {
  .ant-picker-panel-container .ant-picker-panels {
    flex-wrap: wrap;
  }

  .ant-picker-range-wrapper {
    min-width: unset !important;
    max-width: 280px;
  }
}

.custom-select {
  .select__control {
    border-color: @gray-5 !important;
    border-radius: 2px;
    box-shadow: none;
    outline: none !important;

    &:hover,
    &:focus {
      border-color: @primary-color !important;
    }
  }

  .select__menu {
    z-index: 99;

    .select__option--is-selected {
      color: @primary-color;
      background-color: rgba(2, 62, 125, 0.1);
    }
  }
}

.ant-select {
  .ant-select-selection-placeholder,
  .ant-select-arrow {
    color: inherit;
  }
}

.reactEasyCrop_Contain {
  width: unset;
}
