.vui-statistic-card {

  .info-wrapper {

    .value {
      font-size: 24px;
      margin-bottom: 5px;
      line-height: normal;
    }

    .label {
      text-transform: uppercase;
      font-weight: 500;
      margin-bottom: 5px;
      display: inline-block;
      font-size: 13px;
      letter-spacing: 0.5px;
    }

    .subtitle {
      margin-bottom: 0px;
      color: rgb(131, 146, 165);
    }
  }
}

@primary-color: #2664DD;@white-color: #FFFFFF;@black-color: #000000;@error-color: #FF4D4F;@warning-color: #FAAD14;@success-color: #52C41A;@link-color: #1890FF;@border-color: #D9D9D9;@secondary-text-color: rgba(0, 0, 0, 0.48);@body-text-color: rgba(0, 0, 0, 0.64);@title-color: rgba(0, 0, 0, 0.96);