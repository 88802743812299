body {
  font-family: "ageo", sans-serif;
  overflow-x: hidden;
}

.container {
  width: 100%;
  margin: auto;
}

.make-container(@minWidth, @breakpoint) {
  @media (min-width: @minWidth) {
    .container {
      max-width: @breakpoint;
    }
  }
}

.make-container(@screen-xs-min, @screen-xs);
.make-container(@screen-sm-min, @screen-sm);
.make-container(@screen-md-min, @screen-md);
.make-container(@screen-lg-min, @screen-lg);
.make-container(@screen-xl-min, @screen-xl);

img {
  width: 100%;
}

#auth-layout {
  .container-wrapper {
    padding: 30px;
    min-height: 90vh;
    background-color: #f6f6f6;
    display: flex;
    justify-content: center;
  }

  .main-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
  }

  .footer {
    background-color: #f6f6f6;
    text-align: center;
    min-height: 10vh;
  }

  .copyright {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 12px;
    color: @secondary-text-color;
  }

  .form-wrapper {
    width: 500px;
    padding: 40px;
    background-color: @gray-1;
    border-radius: 15px;

    .form-logo {
      margin: 20px auto 40px;
      width: 100%;
      text-align: center;
      .text-logo {
        font-size: x-large;
      }
    }

    .form-header {
      margin-bottom: 30px;
    }

    .title {
      margin-bottom: 5px;
      color: @primary-color;
    }

    .note {
      display: block;
      text-align: center;
    }
  }

  .forgot-password-wrapper {
    width: 100%;

    .forgot-password-main-wrapper {
      max-width: 500px;
      padding: 36px 30px;
      background-color: @gray-1;
      border-radius: 15px;
      margin: 0 auto;
      text-align: center;

      .title {
        margin: 24px 0 6px;
        color: @primary-color;
      }

      .subtitle {
        display: block;
        font-size: 14px;
        color: @secondary-text-color;
      }

      .ant-btn-primary {
        margin-top: 12px;
      }
    }
  }

  .error-wrapper {
    width: 100%;

    .error-main-wrapper {
      max-width: 550px;
      margin: auto;
      text-align: center;

      .illustration {
        width: 70%;
        margin-bottom: 15px;
      }
    }
  }

  .back-text {
    position: absolute;
    top: 30px;
    color: black;
    display: flex;
    align-items: center;

    .icon {
      margin-right: 10px;
    }
  }

  .default-small-wrapper {
    width: 100%;

    .inner-section {
      max-width: 450px;
      margin: auto;
    }
  }

  @media only screen and (max-width: @screen-lg) {
    .main-wrapper {
      .illustration-wrapper {
        display: none;
      }

      .form-wrapper {
        width: 100%;
      }
    }

    .container-wrapper {
      padding: 15px;
    }
  }
}

#app-layout {
  ::-webkit-scrollbar {
    width: 3px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgb(131, 146, 165);
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(131, 146, 165);
  }

  .container-wrapper {
    padding-top: 60px;
    min-height: calc(100vh - 40px);
  }

  .navigation-vertical-wrapper {
    position: fixed;
    top: 60px;
    height: calc(100% - 60px);
    width: 240px;
    overflow-y: scroll;

    .ant-menu-inline.ant-menu-root .ant-menu-item {
      margin: 0px;
    }
  }

  @media only screen and (max-width: @screen-lg) {
    .container-wrapper {
      padding-top: 50px;
      min-height: calc(100vh - 72px);
    }
  }
}

.extended-main-wrapper {
  .main-content {
    margin-left: 60px;
    min-height: 100vh;
  }

  @media only screen and (max-width: @screen-lg) {
    display: flex;
    flex-direction: column;
    position: relative;

    .main-content {
      margin-left: 0px;
    }
  }
}

.show-lg {
  display: none;
}

.hide-lg {
  display: block;
}

@media only screen and (min-width: @screen-lg) {
  .show-lg {
    display: block;
  }
  .hide-lg {
    display: none;
  }
}

.text-align-left {
  text-align: left;
}

.text-align-right {
  text-align: right;
}

.text-capitalize {
  text-transform: capitalize;
}
