.vui-section-title {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  padding: 16px 24px 0 24px;

  .left-section{
    &.with-icon{
      display: flex;
      align-items: center;
    }
  }

  @media only screen and (max-width: 576px) {
    flex-direction: column;
    align-items: flex-start;

    .right-section {
      width: 100%;

      .btn-wrapper {
        display: grid;
        grid-template-columns: 1fr;

        .ant-btn {
          width: 100%;
        }
      }
    }
  }
}

@primary-color: #2664DD;@white-color: #FFFFFF;@black-color: #000000;@error-color: #FF4D4F;@warning-color: #FAAD14;@success-color: #52C41A;@link-color: #1890FF;@border-color: #D9D9D9;@secondary-text-color: rgba(0, 0, 0, 0.48);@body-text-color: rgba(0, 0, 0, 0.64);@title-color: rgba(0, 0, 0, 0.96);