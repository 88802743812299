.ant-tabs{
  .ant-tabs-ink-bar{
    background: @primary-color;
  }
  .ant-tabs-tab{
    margin: 4px 12px 0 24px;
    &+ .ant-tabs-tab {
      margin: 4px 12px 0 24px;
    }
    &:hover{
      .ant-tabs-tab-btn {
        color: @primary-color;
      }
    }
    &.ant-tabs-tab-active {
      .ant-tabs-tab-btn {
        color: @primary-color;
      }
    }
  }
}


@primary-color: #2664DD;@white-color: #FFFFFF;@black-color: #000000;@error-color: #FF4D4F;@warning-color: #FAAD14;@success-color: #52C41A;@link-color: #1890FF;@border-color: #D9D9D9;@secondary-text-color: rgba(0, 0, 0, 0.48);@body-text-color: rgba(0, 0, 0, 0.64);@title-color: rgba(0, 0, 0, 0.96);