.vui-info-card {
  width: 100%;
  .ant-card-body{
    padding: 20px 16px;
    .vui-info-card-title {
      font-size: 14px;
      color: @secondary-text-color;
      font-weight: 500;
      margin-bottom: 3px;
      line-height: 1;
    }

    .vui-info-card-value{
      color: @title-color;
      font-size: 24px;
      font-weight: 600;
    }
    .info-value {
      margin-top: 4px;
      font-size: 12px;
      color: rgb(131, 146, 165);

      .percentage {
        &.up {
          color: @success-color;
        }
        &.down {
          color: @error-color;
        }
      }
    }
  }
}

@primary-color: #2664DD;@white-color: #FFFFFF;@black-color: #000000;@error-color: #FF4D4F;@warning-color: #FAAD14;@success-color: #52C41A;@link-color: #1890FF;@border-color: #D9D9D9;@secondary-text-color: rgba(0, 0, 0, 0.48);@body-text-color: rgba(0, 0, 0, 0.64);@title-color: rgba(0, 0, 0, 0.96);