@import '~antd/dist/antd.less';
@import '~@flavioespinoza/edge-css/css/edge.min.css';
@import "_variables";
@import "_button";
@import "_override";
@import "_layout";
@import "_component";

body {
  font-family: "ageo", sans-serif;
}

@primary-color: #2664DD;@white-color: #FFFFFF;@black-color: #000000;@error-color: #FF4D4F;@warning-color: #FAAD14;@success-color: #52C41A;@link-color: #1890FF;@border-color: #D9D9D9;@secondary-text-color: rgba(0, 0, 0, 0.48);@body-text-color: rgba(0, 0, 0, 0.64);@title-color: rgba(0, 0, 0, 0.96);