.vui-comparison-card {
  height: 100%;

  .ant-card-head {
    font-size: 14px;
  }

  .legends {
    list-style: none;
    padding: 0px;
    margin: 0px;
    display: flex;
    align-items: center;
    gap: 10px;

    .item {
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 500;
      display: flex;
      align-items: center;
      cursor: pointer;

      .dot {
        width: 10px;
        height: 10px;
        display: inline-block;
        background: #8392a5;
        border-radius: 100%;
        margin-right: 5px;
      }
    }
  }

  .list-wrapper {
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
    margin-bottom: 15px;

    .item {
      flex-basis: calc((100% / 3) - 15px) ;
      display: flex;
      flex-direction: column;

      .value {
        font-size: 24px;
        font-weight: 500;
        line-height: 1.2;
      }

      .label {
        font-size: 12px;
        font-weight: 600;
        margin-bottom: 5px;
      }


      .description {
        color: #8392a5;
        font-size: 12px;
        margin: 0px;
      }
    }
  }

  .chart-wrapper {

  }

  @media only screen and (max-width: 992px) {
    .list-wrapper {
      .item {
        flex-basis: 100%;
      }
    }
  }
}


@primary-color: #2664DD;@white-color: #FFFFFF;@black-color: #000000;@error-color: #FF4D4F;@warning-color: #FAAD14;@success-color: #52C41A;@link-color: #1890FF;@border-color: #D9D9D9;@secondary-text-color: rgba(0, 0, 0, 0.48);@body-text-color: rgba(0, 0, 0, 0.64);@title-color: rgba(0, 0, 0, 0.96);