.vui-table-card {
  height: 100%;
  .ant-card-body {
    padding: 0;
    position: relative;
    overflow: hidden;
  }

  .info-wrapper {
    padding: 20px 25px;

    .title {
      font-size: 11px;
      font-weight: 500;
      margin-bottom: 0px;
      line-height: 1;
      text-transform: uppercase;
    }

    .value-wrapper {
      .value {
        font-size: 20px;
        font-weight: 500;
        margin-right: 5px;
      }

      .info-value {
        font-size: 12px;
        color: rgb(131, 146, 165);
      }

      .percentage {
        &.up {
          color: #52c41a;
        }

        &.down {
          color: #f5222d;
        }
      }
    }
  }
}

@primary-color: #2664DD;@white-color: #FFFFFF;@black-color: #000000;@error-color: #FF4D4F;@warning-color: #FAAD14;@success-color: #52C41A;@link-color: #1890FF;@border-color: #D9D9D9;@secondary-text-color: rgba(0, 0, 0, 0.48);@body-text-color: rgba(0, 0, 0, 0.64);@title-color: rgba(0, 0, 0, 0.96);